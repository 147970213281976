import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * SmartText is a customizable text input component with suggestions, word highlighting, and hint mapping.
 */
const SmartText = (props) => {
  const {
    id,
    questionList,
    value,
    setProps,
    textareaStyle,
    suggestionsContainerStyle,
    suggestionButtonStyle,
    suggestionButtonSelectedStyle,
    clearButtonStyle,
    tooltipButtonStyle,
    placeholder,
    highlightedWords,
    highlightedWordStyle,
    clickedWord,
    isToolkitOpen,
  } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [currentHighlight, setCurrentHighlight] = useState('');
  const [currentKeys, setCurrentKeys] = useState([]);
  const editableDivRef = useRef(null);
  const suggestionsContainerRef = useRef(null);
  const suggestionRefs = useRef([]);
  const tooltipRef = useRef(null);

  // Handle placeholder rendering when the value is empty
  const isPlaceholderVisible = value === '';

  useEffect(() => {
    if (tooltipVisible) {
      setShowSuggestions(false);
    }
  }, [tooltipVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editableDivRef.current &&
        !editableDivRef.current.contains(event.target) &&
        suggestionsContainerRef.current &&
        !suggestionsContainerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
        setTooltipVisible(false);
      } else if (
        tooltipVisible &&
        editableDivRef.current &&
        editableDivRef.current.contains(event.target) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        // Close tooltip if clicked inside editableDiv but outside the tooltip itself,
        setTooltipVisible(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    if (isToolkitOpen) {
      setShowSuggestions(false);
    }
  }, [isToolkitOpen]);

  const filterSuggestions = (inputValue, forceShowSuggestions = false) => {
    // Prevent suggestions if toolkit is active
    if (isToolkitOpen) {
      setShowSuggestions(false);
      return;
    }

    // Allow suggestions if forceShowSuggestions is true, even if tooltipVisible is true
    if (!forceShowSuggestions && tooltipVisible) {
      setShowSuggestions(false);
      return;
    }
  
    if (inputValue === '') {
      setSuggestions(questionList);
      setShowSuggestions(questionList.length > 0);
    } else {
      const filteredSuggestions = questionList.filter(item =>
        item.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(filteredSuggestions.length > 0);
    }
  };  

  useEffect(() => {
    const handleResize = () => {
      if (editableDivRef.current && suggestionsContainerRef.current) {
        // Update the width of the suggestions container to always match the width of editable div
        suggestionsContainerRef.current.style.width = `${editableDivRef.current.offsetWidth}px`;
      }
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial width when the component mounts
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [value]);

  useEffect(() => {
    if (selectedSuggestionIndex >= 0 && suggestionRefs.current[selectedSuggestionIndex]) {
      suggestionRefs.current[selectedSuggestionIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedSuggestionIndex]);

  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return 0;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(editableDivRef.current);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    
    return preCaretRange.toString().length;
  };

  const setCaretPosition = (position) => {
    const selection = window.getSelection();
    const range = document.createRange();
    let charIndex = 0;

    const nodeIterator = document.createNodeIterator(
      editableDivRef.current,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );

    let currentNode;
    while ((currentNode = nodeIterator.nextNode())) {
      const nextCharIndex = charIndex + currentNode.length;
      if (position <= nextCharIndex) {
        range.setStart(currentNode, position - charIndex);
        range.setEnd(currentNode, position - charIndex);
        break;
      }
      charIndex = nextCharIndex;
    }

    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleTextChange = (event) => {
    const caretPosition = getCaretPosition();
    const newValue = editableDivRef.current.innerText;
    setProps({ value: newValue });
    
    // Only filter suggestions if neither tooltip nor toolkit is open
    if (!tooltipVisible && !isToolkitOpen) {
      filterSuggestions(newValue.trim());
    }
    
    requestAnimationFrame(() => {
        setCaretPosition(caretPosition); // Restore caret position
    });
  };

  const handleTextAreaClick = (event) => {
    // Check if the click target is not a highlighted word
    if (!event.target.matches('.highlighted-word-style')) {
      // Close the tooltip when clicking outside highlighted words
      setTooltipVisible(false);
  
      // Proceed to filter suggestions unless the toolkit is open
      if (!isToolkitOpen) {
        // Filter suggestions relevant to the current content in editableDiv
        filterSuggestions(editableDivRef.current.innerText.trim(), true);
      } else {
        setShowSuggestions(false); // Ensure suggestions are hidden if the toolkit is active
      }
    } else {
      // When clicking on a highlighted word, do not close the tooltip
      // Also, do not show suggestions when clicking on a highlighted word
      setShowSuggestions(false);
    }
  };

  const handleClearClick = () => {
    setProps({ value: '' });
    if (editableDivRef.current) {
      editableDivRef.current.innerHTML = '';
    }
    setShowSuggestions(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setSelectedSuggestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, suggestions.length - 1)
      );
    }
    if (event.key === 'ArrowUp') {
      setSelectedSuggestionIndex((prevIndex) =>
        Math.max(prevIndex - 1, 0)
      );
    }
    if (event.key === 'Enter' && selectedSuggestionIndex >= 0) {
      const selectedSuggestion = suggestions[selectedSuggestionIndex];
      setProps({ value: selectedSuggestion });
      if (editableDivRef.current) {
        editableDivRef.current.innerText = selectedSuggestion;
      }
      setShowSuggestions(false);
    }
  };

  const highlightText = (input) => {
    if (!input || highlightedWords.length === 0) {
      return input;
    }
  
    highlightedWords.forEach(({ highlight }, index) => {
      // Escape any special characters in highlight for regex
      const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`\\b(${escapedHighlight})\\b`, 'gi');
      input = input.replace(regex, (matchedText) => {
        // Append an index to create a unique ID for each instance
        const id = `highlight-word-${highlight.toLowerCase()}-${index}`;
        const style = Object.entries(highlightedWordStyle || {}).map(([k, v]) => `${k}:${v}`).join(';');
        return `<span id="${id}" class="highlighted-word-style" style="cursor: pointer; ${style}" data-word="${highlight.toLowerCase()}">${matchedText}</span>`;
      });
    });
  
    return input;
  };
  
  const handleHighlightClick = (word, event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
  
    // Close the currently open tooltip if any
    setTooltipVisible(false);
  
    // Find the data for the clicked word
    const wordData = highlightedWords.find(w => w.highlight.toLowerCase() === word.toLowerCase());
    const keys = wordData && wordData.hints ? wordData.hints.map(hint => hint.key) : [];
  
    setCurrentHighlight(word);
    setCurrentKeys(keys);
    setProps({ clickedWord: word });
  
    // Get the clicked element's bounding rectangle to position the tooltip accurately
    const rect = event.target.getBoundingClientRect();
    const containerRect = editableDivRef.current.getBoundingClientRect();
  
    // Set the new tooltip position
    setTooltipPosition({
      top: rect.top - containerRect.top + rect.height + 2,
      left: rect.left - containerRect.left,
    });
  
    // Open the tooltip for the new word
    setTooltipVisible(true);
  };
  
  const attachClickListeners = () => {
    const highlightedElements = editableDivRef.current.querySelectorAll('[data-word]');
    highlightedElements.forEach((el) => {
      el.addEventListener('click', (event) => {
        const word = event.target.getAttribute('data-word');
        handleHighlightClick(word, event);
      });
    });
  };

  const handleKeyClick = (key) => {
    const editableDivText = editableDivRef.current.innerHTML;
  
    // Find the specific instance to replace
    const updatedText = editableDivText.replace(
      new RegExp(`(<span.*?>)${currentHighlight}(</span>)`, 'i'),
      `$1${key}$2`
    );
  
    // Update the content with new text, keeping other spans intact
    editableDivRef.current.innerHTML = updatedText;
  
    // Update the props and hide the tooltip
    setProps({ value: editableDivRef.current.innerText });
    setTooltipVisible(false);
  
    // Re-attach click listeners to the remaining highlighted words
    attachClickListeners();
  };

  useEffect(() => {
    if (editableDivRef.current) {
      const selection = window.getSelection();
      const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
      const caretPosition = range ? range.endOffset : 0;
      const focusNode = range ? range.endContainer : null;
  
      const highlightedHTML = highlightText(value);
  
      if (editableDivRef.current.innerHTML !== highlightedHTML) {
        editableDivRef.current.innerHTML = highlightedHTML;
        attachClickListeners();
  
        // Restore the caret position
        if (focusNode && caretPosition !== null) {
          const nodeIterator = document.createNodeIterator(
            editableDivRef.current,
            NodeFilter.SHOW_TEXT,
            null,
            false
          );
  
          let currentNode;
          let charIndex = 0;
  
          while ((currentNode = nodeIterator.nextNode())) {
            if (currentNode === focusNode) {
              const newRange = document.createRange();
              newRange.setStart(currentNode, caretPosition);
              newRange.setEnd(currentNode, caretPosition);
              selection.removeAllRanges();
              selection.addRange(newRange);
              break;
            }
            charIndex += currentNode.length;
          }
        }
      }
    }
  }, [value, highlightedWords]);  
  

  return (
    <div className="g-0 ms-auto flex-nowrap mt-3 mt-md-0" id={id || 'searchbox'} style={{ position: 'relative', width: '100%' }}>
      <div style={{ position: 'relative', width: '100%' }}>
        {isPlaceholderVisible && (
          <span className='placeholder-text'
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              padding: '1.2em',
              color: '#888',
              pointerEvents: 'none',
              zIndex:4
            }}
          >
            {placeholder}
          </span>
        )}

        <div
          ref={editableDivRef}
          id={id}
          contentEditable="true"
          suppressContentEditableWarning={true}
          className="pro-contact-form-message combo w-input"
          style={{
            ...textareaStyle,
            position:"relative",
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            outline: 'none',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            color: 'initial',
            zIndex: 2,
          }}
          onInput={handleTextChange}
          onClick={(event) => handleTextAreaClick(event)} // Pass event to the handler
          onKeyDown={handleKeyDown}
        >
          {value}
        </div>

        {/* Tooltip for keys as buttons */}
        {tooltipVisible && (
        <div
            ref={tooltipRef} // Reference the tooltip
            className="tooltip"
            style={{
                position: 'absolute',
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
                backgroundColor: '#f9f9f9',
                borderRadius: '4px',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                zIndex: 10,
            }}
        >
            {currentKeys.length > 0 ? currentKeys.map((key, index) => (
              <button
                key={index}
                onClick={() => handleKeyClick(key)}
                className='hint-button'
                style={{
                  
                  display: 'block',
                  backgroundColor: '#fff',
                  color: '#000',
                  border: 'none',
                  padding: '1em',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  ...tooltipButtonStyle
                }}
              >
                {key}
              </button>
            )) : <span>No hints available</span>}
          </div>
        )}

        {value && (
          <button
            onClick={handleClearClick}
            style={{
              position: 'absolute',
              right: '4rem',
              top: '10px',
              backgroundColor: '#616161',
              border: '2px solid #616161',
              fontSize: '20px',
              cursor: 'pointer',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
              color: 'white',
              zIndex: 11,
              ...clearButtonStyle,
            }}
          >
            ×
          </button>
        )}

        {/* Suggestion Container */}
        <div
          ref={suggestionsContainerRef}
          id="suggestions-container"
          style={{
            ...suggestionsContainerStyle,
            width: `${editableDivRef.current ? editableDivRef.current.offsetWidth : '100%'}px`,
            display: showSuggestions && !tooltipVisible && suggestions.length > 0 ? 'block' : 'none',
            position: 'absolute',
            top: editableDivRef.current ? `${editableDivRef.current.offsetHeight / 2}px` : '100%',
            paddingTop: editableDivRef.current ? `${editableDivRef.current.offsetHeight / 2}px` : '100%',
            left: '0',
            zIndex: 1,
            boxSizing: 'border-box',
          }}
        >
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              ref={(el) => (suggestionRefs.current[index] = el)}
              className={`suggestion ${selectedSuggestionIndex === index ? 'selected' : ''}`}
              style={{
                ...suggestionButtonStyle,
                ...(selectedSuggestionIndex === index ? suggestionButtonSelectedStyle : {}),
              }}
              onClick={() => {
                setProps({ value: suggestion });
                setTimeout(() => {
                  setShowSuggestions(false);
                }, 0);
              }}
            >
              {suggestion}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

SmartText.defaultProps = {
  id: '',
  textareaStyle: {},
  suggestionsContainerStyle: {},
  suggestionButtonStyle: {},
  suggestionButtonSelectedStyle: {},
  setProps: () => {},
  clearButtonStyle: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
  tooltipButtonStyle: {},
  placeholder: 'Type something...',
  highlightedWords: [],
  highlightedWordStyle: {},
  clickedWord: null,
  isToolkitOpen: false,
};

SmartText.propTypes = {
  id: PropTypes.string,
  questionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  setProps: PropTypes.func,
  textareaStyle: PropTypes.object,
  suggestionsContainerStyle: PropTypes.object,
  suggestionButtonStyle: PropTypes.object,
  suggestionButtonSelectedStyle: PropTypes.object,
  clearButtonStyle: PropTypes.object,
  tooltipButtonStyle: PropTypes.object,
  placeholder: PropTypes.string,
  highlightedWords: PropTypes.arrayOf(PropTypes.shape({
    highlight: PropTypes.string.isRequired,
    hints: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
    }))
  })),
  highlightedWordStyle: PropTypes.object,
  clickedWord: PropTypes.string,
  isToolkitOpen: PropTypes.bool,
};

export default SmartText;
